<template>
<div class="editinfo-page">
    <div class="edit-body">
        <div class="edit-item">
            <p>头　像：</p>
            <van-uploader>
                <div class="avatar-upload">
                    <van-icon name="photo-o" size="20px" color="#fff" />
                </div>
            </van-uploader>
        </div>
        <div class="edit-item">
            <p>昵　称：</p>
            <div class="nickname-input-box">
                <van-field v-model="value" input-align="right" placeholder="张三" />
            </div>
        </div>
        <div class="edit-item">
            <p>手机号：</p>
            <div class="nickname-input-box">
                <van-field v-model="tel" type="tel" input-align="right" placeholder="18782262026" />
            </div>
        </div>
    </div>
    <div class="save-info-button">
        <van-button color="#DF5F0B" :block=true>保存信息</van-button>
    </div>
</div>
</template>

<script setup>
import { ref } from "vue";
let value = ref("");
let tel = ref("")
</script>

<style lang="scss" scoped>
.editinfo-page{
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;
    .edit-body{
        min-height: 50px;
        background: #fff;
        padding: 20px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        .edit-item:nth-child(1){
            padding-top: 0;
        }
        .edit-item{
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0;
            .avatar-upload{
                width: 40px;
                height: 40px;
                border-radius: 50px;
                background: #C4C4C4;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .nickname-input-box{
                width: 130px;
            }
        }
        .edit-item:nth-child(3){
            border: none;
        }
    }
    .save-info-button{
        padding: 14px;
    }
}
</style>
